/* eslint-disable @typescript-eslint/naming-convention */
import {Casts, CastTypes, Model} from '@app/core/model';
import {Account} from '@app/core/models/account/account';
import {investmentProductTypeLabels, productTypeLabels} from '@app/core/models/account/const/product-type-labels';
import {EProductGroupType} from '@app/core/models/account/enum/product-group-type';
import {EProductType} from '@app/core/models/account/enum/product-type';
import {checkRight, Rights} from '@app/core/models/account/rights';

import {Currency} from './currency';
import {Group} from './group';
import {Platform} from './platform';
import {Variants} from './variants';

export class Product extends Model {
  private _variants: Variants;
  private _platform: Platform;
  private _group: Group;
  private _currency: Currency;

  public id: number;
  public name: string;
  public localizedCaption?: string;
  public caption: string;
  public type: number;
  public factory: number;
  public enabled: number;
  public code: string;
  public platform_group: string[] | null;
  public hedge: boolean;
  public max: number;
  public rights: number;
  public link_info?: string;
  public agreement_link?: string;
  public is_cash: boolean;

  public readonly casts: Casts = {
    id: [CastTypes.Number],
    name: [CastTypes.String],
    localizedCaption: [CastTypes.Undefined, CastTypes.String],
    caption: [CastTypes.String],
    type: [CastTypes.Number],
    factory: [CastTypes.Number],
    enabled: [CastTypes.Number],
    code: [CastTypes.String],
    platform_group: [[CastTypes.String], CastTypes.Null],
    hedge: [CastTypes.Boolean],
    max: [CastTypes.Number],
    rights: [CastTypes.Number],
    variants: [Variants],
    platform: [Platform],
    group: [Group, CastTypes.Null],
    currency: [Currency, CastTypes.Null],
    link_info: [CastTypes.String, CastTypes.Null],
    agreement_link: [CastTypes.String, CastTypes.Null],
    is_cash: [CastTypes.Boolean],
  };

  public get currency(): Currency {
    return this._currency ? this._currency : new Currency();
  }

  public set currency(value: Currency) {
    this._currency = value;
  }

  public get group(): Group {
    return this._group ? this._group : new Group();
  }

  public set group(value: Group) {
    this._group = value;
  }

  public get platform(): Platform {
    return this._platform ? this._platform : new Platform();
  }

  public set platform(value: Platform) {
    this._platform = value;
  }

  public get variants(): Variants {
    return this._variants ? this._variants : new Variants();
  }

  public set variants(value: Variants) {
    this._variants = value;
  }

  public get typeLabel(): string {
    return this.isInvestmentType ? investmentProductTypeLabels[this.group.type] : productTypeLabels[this.type] ?? '';
  }

  public get typeReal(): boolean {
    return this.type === EProductType.Real;
  }

  public get typePersonal(): boolean {
    return this.type === EProductType.Personal;
  }

  public get typeDemo(): boolean {
    return this.type === EProductType.Demo;
  }

  public get typePartner(): boolean {
    return this.type === EProductType.Partner;
  }

  public get typeExchange(): boolean {
    return this.type === EProductType.Exchange;
  }

  public get typeExternal(): boolean {
    return this.type === EProductType.External;
  }

  public get isInvestmentMasterType(): boolean {
    const investmentMasterTypes = [
      EProductGroupType.B2CopyMaster,
      EProductGroupType.PammMaster,
      EProductGroupType.MamMaster,
    ];
    return investmentMasterTypes.includes(this.group.type);
  }

  public get isInvestmentFollowerType(): boolean {
    const investmentFollowerTypes = [
      EProductGroupType.B2CopyFollower,
      EProductGroupType.PammFollower,
      EProductGroupType.MamFollower,
    ];
    return investmentFollowerTypes.includes(this.group.type);
  }

  public get isInvestmentType(): boolean {
    return this.isInvestmentMasterType || this.isInvestmentFollowerType;
  }

  public get isPaymentType(): boolean {
    return this.group.type === EProductGroupType.Payment;
  }

  public get rightsEnabled(): boolean {
    return checkRight(this, Rights.RIGHTS_ENABLED);
  }

  public get rightsVisible(): boolean {
    return checkRight(this, Rights.RIGHTS_VISIBLE);
  }

  public get rightsEnabledAndVisible(): boolean {
    return this.rightsEnabled && this.rightsVisible;
  }

  public get rightsDeposit(): boolean {
    return (this.rights & Rights.RIGHTS_DEPOSIT) === Rights.RIGHTS_DEPOSIT;
  }

  public get rightsTransfer(): boolean {
    return (this.rights & Rights.RIGHTS_TRANSFER) === Rights.RIGHTS_TRANSFER;
  }

  public get rightsTransferDeposit(): boolean {
    return (this.rights & Rights.RIGHTS_TRANSFER_DEPOSIT) === Rights.RIGHTS_TRANSFER_DEPOSIT;
  }

  public get rightsTransferWithdraw(): boolean {
    return (this.rights & Rights.RIGHTS_TRANSFER_WITHDRAW) === Rights.RIGHTS_TRANSFER_WITHDRAW;
  }

  public get rightsWithdraw(): boolean {
    return (this.rights & Rights.RIGHTS_WITHDRAW) === Rights.RIGHTS_WITHDRAW;
  }

  public get rightsExchange(): boolean {
    return (this.rights & Rights.RIGHTS_EXCHANGE) === Rights.RIGHTS_EXCHANGE;
  }

  public get rightsDenyAccountCreateTr(): boolean {
    return (this.rights & Rights.RIGHTS_DENY_ACCOUNT_CREATE_TR) === Rights.RIGHTS_DENY_ACCOUNT_CREATE_TR;
  }

  public get isPersonalOrMargin(): boolean {
    return this.typePersonal || this.typeExternal || this.platform.isB2Margin();
  }

  public get isAvailableForTrading(): boolean {
    return this.isPersonalOrMargin && this.rightsEnabledAndVisible;
  }

  public get availableToCreate(): boolean {
    return this.typePersonal || this.platform.isB2Margin() || this.createAvailable;
  }

  public get createAvailable(): boolean {
    return !checkRight(this, Rights.RIGHTS_DENY_ACCOUNT_CREATE_TR);
  }

  public get isOneZero(): boolean {
    return this.platform.isOneZero();
  }

  public get isPrimeXm(): boolean {
    return this.platform.isPrimeXm();
  }

  public get isB2Margin(): boolean {
    return this.platform.isB2Margin();
  }

  public get isB2MarginWithoutCash(): boolean {
    return this.platform.isB2Margin() && !this.is_cash;
  }

  public get isB2MarginWithCash(): boolean {
    return this.platform.isB2Margin() && this.is_cash;
  }
}

export function haveAvailableInstance(product: Product, accounts: Account[]): boolean {
  const instancesCount = accounts.filter(a => a.product.id === product.id).length;

  return product.max < 0 || product.max > instancesCount;
}
