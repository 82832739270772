import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  public labelsDictionary: TLabels = {
    /* eslint-disable @typescript-eslint/naming-convention */
    Email: () => this.translate.get('Email'),
    Password: () => this.translate.get('Password'),
    email: () => this.translate.get('Email'),
    password: () => this.translate.get('Password'),
    'Password confirmation': () => this.translate.get('Password confirmation'),
    password_hint: () => this.translate.get('password_hint'),
    password_confirmation_hint: () => this.translate.get('password_confirmation_hint'),
    'labels.agreement': () => this.translate.get('labels.agreement'),
    'labels.agreement_additional': () => this.translate.get('labels.agreement_additional'),
    'I am an adult': () => this.translate.get('I am an adult'),
    'I am not a US citizen': () => this.translate.get('I am not a US citizen'),
    Code: () => this.translate.get('Code'),
    'Select authentication method': () => this.translate.get('Select authentication method'),
    url: () => this.translate.get('url'),
    'First name': () => this.translate.get('First name'),
    'Last name': () => this.translate.get('Last name'),
    'Middle name': () => this.translate.get('Middle name'),
    'Phone number': () => this.translate.get('Phone number'),
    'Payment currency': () => this.translate.get('Payment currency'),
    'Enter code': () => this.translate.get('Enter code'),
    Account: () => this.translate.get('Account'),
    Method: () => this.translate.get('Method'),
    'Select currency': () => this.translate.get('Select currency'),
    'Select your document': () => this.translate.get('Select your document'),
    Amount: () => this.translate.get('Amount'),
    'Given name': () => this.translate.get('Given name'),
    'Family name': () => this.translate.get('Family name'),
    Birthday: () => this.translate.get('Birthday'),
    Phone: () => this.translate.get('Phone'),
    Address: () => this.translate.get('Address'),
    City: () => this.translate.get('City'),
    State: () => this.translate.get('State'),
    Country: () => this.translate.get('Country'),
    'Postal code': () => this.translate.get('Postal code'),
    'Proof of ID': () => this.translate.get('Proof of ID'),
    Passport: () => this.translate.get('Passport'),
    Selfie: () => this.translate.get('Selfie'),
    'Transfer amount': () => this.translate.get('Transfer amount'),
    'Select account': () => this.translate.get('Select account'),
    'If yes please provide details': () => this.translate.get('If yes please provide details'),
    'I am not US, Iran or North Korea resident': () => this.translate.get('I am not US, Iran or North Korea resident'),
    wallet_address: () => this.translate.get('wallet_address'),
    currency_code: () => this.translate.get('currency_code'),
    no_destination_tag: () => this.translate.get('no_destination_tag'),
    destination_tag: () => this.translate.get('destination_tag'),
    'ID type': () => this.translate.get('ID type'),
    'ID card': () => this.translate.get('ID card'),
    'Mainland China': () => this.translate.get('Mainland China'),
    Vietnam: () => this.translate.get('Vietnam'),
    praxis_variable_2: () => this.translate.get('PraxisCustomField'),
    'Hong Kong': () => this.translate.get('Hong Kong'),
    China: () => this.translate.get('China'),
    Others: () => this.translate.get('Others'),
    'Certificate number': () => this.translate.get('Certificate number'),
    'Region country': () => this.translate.get('Region country'),
    Name: () => this.translate.get('Name'),
    'Beneficiary Bank address': () => this.translate.get('Beneficiary Bank address'),
    'Beneficiary Bank name': () => this.translate.get('Beneficiary Bank name'),
    'Beneficiary Address': () => this.translate.get('Beneficiary Address'),
    'Beneficiary Name': () => this.translate.get('Beneficiary Name'),
    'Beneficiary IBAN': () => this.translate.get('Beneficiary IBAN'),
    'Beneficiary Bank SWIFT': () => this.translate.get('Beneficiary Bank SWIFT'),
    'Correspondent Bank address': () => this.translate.get('Correspondent Bank address'),
    'Correspondent Bank name': () => this.translate.get('Correspondent Bank name'),
    'Correspondent Bank SWIFT': () => this.translate.get('Correspondent Bank SWIFT'),
    'Card Bank': () => this.translate.get('Card Bank'),
    'Card bank': () => this.translate.get('Card Bank'),
    'Address Type': () => this.translate.get('Address Type'),
    'Card holder': () => this.translate.get('Card holder'),
    'The Email has already been taken': () => this.translate.get('The email has already been taken'),
    'The nickname field must not be greater than 32 characters.': () =>
      this.translate.get('The nickname field must not be greater than 32 characters.'),
    'This nickname already exists': () => this.translate.get('This nickname already exists'),
    'The account id has already been taken.': () => this.translate.get('The account id has already been taken'),
    'Destination account not found': () => this.translate.get('Destination account not found'),
    'Session expired': () => this.translate.get('Session expired'),
    // PBSR Verification keys
    'verification.company_data.inn': () => this.translate.get('Verification.VerificationSteps.Field.CompanyData.Inn'),
    'verification.company_data.kpp': () => this.translate.get('Verification.VerificationSteps.Field.CompanyData.Kpp'),
    'verification.company_data.ogrn': () => this.translate.get('Verification.VerificationSteps.Field.CompanyData.Ogrn'),
    'verification.company_data.bik': () => this.translate.get('Verification.VerificationSteps.Field.CompanyData.Bik'),
    'verification.company_data.swift': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.Swift'),
    'verification.company_data.property_form': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.PropertyForm'),
    'verification.company_data.name_ru': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.NameRu'),
    'verification.company_data.shortname_ru': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.ShortnameRu'),
    'verification.company_data.name': () => this.translate.get('Verification.VerificationSteps.Field.CompanyData.Name'),
    'verification.company_data.shortname': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.Shortname'),
    'verification.company_data.fax': () => this.translate.get('Verification.VerificationSteps.Field.CompanyData.Fax'),
    'verification.company_data.url': () => this.translate.get('Verification.VerificationSteps.Field.CompanyData.Url'),
    'verification.company_data.ogrn_registration_date': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.OgrnRegistrationDate'),
    'verification.company_data.ogrn_body_state_registration': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.OgrnBodyStateRegistration'),
    'verification.company_data.ogrn_body_state_registration_address': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.OgrnBodyStateRegistrationAddress'),
    'verification.company_data.has_old_registration_date': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.HasOldRegistrationDate'),
    'verification.company_data.old_registration_number': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.OldRegistrationNumber'),
    'verification.company_data.old_registration_date': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.OldRegistrationDate'),
    'verification.company_data.old_registration_body': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.OldRegistrationBody'),
    'verification.company_data.capital': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.Capital'),
    'verification.company_data.capital_currency': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.CapitalCurrency'),
    'verification.company_data.okato': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.Okato'),
    'verification.company_data.okved': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.Okved'),
    'verification.company_data.oktmo': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.Oktmo'),
    'verification.company_data.okfs': () => this.translate.get('Verification.VerificationSteps.Field.CompanyData.Okfs'),
    'verification.company_data.okogu': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.Okogu'),
    'verification.company_data.okopf': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.Okopf'),
    'verification.company_data.okpo': () => this.translate.get('Verification.VerificationSteps.Field.CompanyData.Okpo'),
    'verification.company_data.license_type': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.LicenseType'),
    'verification.company_data.license_number': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.LicenseNumber'),
    'verification.company_data.license_date_start': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.LicenseDateStart'),
    'verification.company_data.license_issued_by': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.LicenseIssuedBy'),
    'verification.company_data.license_validity': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.LicenseValidity'),
    'verification.company_data.license_list_of_activities': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.LicenseListOfActivities'),
    'verification.company_data.has_management_without_a_power_of_attorney': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.HasManagementWithoutAPowerOfAttorney'),
    'verification.company_data.accreditation_number': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.AccreditationNumber'),
    'verification.company_data.company_email': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.CompanyEmail'),
    'verification.company_data.company_phone': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.CompanyPhone'),
    'verification.company_data.is_beneficiary': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.IsBeneficiary'),
    'verification.company_data.authority': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.Authority'),
    'verification.company_data.interests_organization': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyData.InterestsOrganization'),
    'verification.company_addresses.country': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.Country'),
    'verification.company_addresses.region': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.Region'),
    'verification.company_addresses.city': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.City'),
    'verification.company_addresses.index': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.Index'),
    'verification.company_addresses.locality': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.Locality'),
    'verification.company_addresses.street': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.Street'),
    'verification.company_addresses.house': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.House'),
    'verification.company_addresses.building': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.Building'),
    'verification.company_addresses.construction': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.Construction'),
    'verification.company_addresses.apartment': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.Apartment'),
    'verification.company_addresses.use_address_as_mailing': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.UseAddressAsMailing'),
    'verification.company_addresses.actual_same_as_registration': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.ActualSameAsRegistration'),
    'verification.company_addresses.tax_residency_country': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.TaxResidencyCountry'),
    'verification.company_addresses.tin_or_ssn': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyAddresses.TinOrSsn'),
    'verification.company_documents.signatures_and_stamp': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.SignaturesAndStamp'),
    'verification.company_documents.power_of_attorney': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.PowerOfAttorney'),
    'verification.company_documents.affiliates_list': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.AffiliatesList'),
    'verification.company_documents.latest_charter': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.LatestCharter'),
    'verification.company_documents.eio_charter': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.EioCharter'),
    'verification.company_documents.eio_identification': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.EioIdentification'),
    'verification.company_documents.past_year_financial_statements': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.PastYearFinancialStatements'),
    'verification.company_documents.past_year_financial_statements_hint': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.PastYearFinancialStatementsHint'),
    'verification.company_documents.tax_return': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.TaxReturn'),
    'verification.company_documents.tax_return_hint': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.TaxReturnHint'),
    'verification.company_documents.lease_agreement': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.LeaseAgreement'),
    'verification.company_documents.lease_agreement_hint': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.LeaseAgreementHint'),
    'verification.company_documents.beneficial_owners_identity': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.BeneficialOwnersIdentity'),
    'verification.company_documents.beneficial_owners_identity_hint': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.BeneficialOwnersIdentityHint'),
    'verification.company_documents.beneficial_owners_approval': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.BeneficialOwnersApproval'),
    'verification.company_documents.beneficial_owners_approval_hint': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.BeneficialOwnersApprovalHint'),
    'verification.company_documents.bank_details': () =>
      this.translate.get('Verification.VerificationSteps.Field.CompanyDocuments.BankDetails'),
    'verification.client_personal_data.first_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.FirstName'),
    'verification.client_personal_data.last_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.LastName'),
    'verification.client_personal_data.middle_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.MiddleName'),
    'verification.client_personal_data.gender': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.Gender'),
    'verification.client_personal_data.country': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.Country'),
    'verification.client_personal_data.passport_type': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.PassportType'),
    'verification.client_personal_data.passport_document': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.PassportDocument'),
    'verification.client_personal_data.passport_document_hint': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.PassportDocumentHint'),
    'verification.client_personal_data.series': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.Series'),
    'verification.client_personal_data.number': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.Number'),
    'verification.client_personal_data.date_of_issue': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.DateOfIssue'),
    'verification.client_personal_data.expiry_date': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.ExpiryDate'),
    'verification.client_personal_data.department_code': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.DepartmentCode'),
    'verification.client_personal_data.issued_by': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.IssuedBy'),
    'verification.client_personal_data.date_of_birth': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.DateOfBirth'),
    'verification.client_personal_data.place_of_birth': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.PlaceOfBirth'),
    'verification.client_personal_data.is_place_birth_in_usa': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.IsPlaceBirthInUsa'),
    'verification.client_personal_data.additional_citizenship_country': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.AdditionalCitizenshipCountry'),
    'verification.client_personal_data.additional_citizenship_passport': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.AdditionalCitizenshipPassport'),
    'verification.client_personal_data.is_all_citizenship_provided': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientPersonalData.IsAllCitizenshipProvided'),
    'verification.client_addresses.country': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.Country'),
    'verification.client_addresses.region': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.Region'),
    'verification.client_addresses.city': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.City'),
    'verification.client_addresses.index': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.Index'),
    'verification.client_addresses.locality': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.Locality'),
    'verification.client_addresses.street': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.Street'),
    'verification.client_addresses.house': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.House'),
    'verification.client_addresses.building': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.Building'),
    'verification.client_addresses.construction': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.Construction'),
    'verification.client_addresses.apartment': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.Apartment'),
    'verification.client_addresses.use_address_as_mailing': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.UseAddressAsMailing'),
    'verification.client_addresses.actual_same_as_registration': () =>
      this.translate.get('Verification.VerificationSteps.Field.ClientAddresses.ActualSameAsRegistration'),
    'verification.tax_residency.inn': () => this.translate.get('Verification.VerificationSteps.Field.TaxResidency.Inn'),
    'verification.tax_residency.snils': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.Snils'),
    'verification.tax_residency.tax_residency_country': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.TaxResidencyCountry'),
    'verification.tax_residency.has_tin_or_ssn': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.HasTinOrSsn'),
    'verification.tax_residency.has_tin_or_ssn.yes': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.HasTinOrSsn.Yes'),
    'verification.tax_residency.has_tin_or_ssn.no': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.HasTinOrSsn.No'),
    'verification.tax_residency.tin_or_ssn': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.TinOrSsn'),
    'verification.tax_residency.tin_or_ssn_missing_reason': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.TinOrSsnMissingReason'),
    'verification.tax_residency.tin_or_ssn_missing_reason_description': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.TinOrSsnMissingReasonDescription'),
    'verification.tax_residency.has_usa_citizenship_renunciation': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.HasUsaCitizenshipRenunciation'),
    'verification.tax_residency.usa_citizenship_renunciation_document': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.UsaCitizenshipRenunciationDocument'),
    'verification.tax_residency.all_tax_residencies_provided': () =>
      this.translate.get('Verification.VerificationSteps.Field.TaxResidency.AllTaxResidenciesProvided'),
    'verification.residency_permissions.document_type': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.DocumentType'),
    'verification.residency_permissions.document': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.Document'),
    'verification.residency_permissions.visa.visa_id': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.Visa.VisaId'),
    'verification.residency_permissions.visa.series': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.Visa.Series'),
    'verification.residency_permissions.visa.number': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.Visa.Number'),
    'verification.residency_permissions.visa.date_issue': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.Visa.DateIssue'),
    'verification.residency_permissions.visa.issued_by': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.Visa.IssuedBy'),
    'verification.residency_permissions.visa.from': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.Visa.From'),
    'verification.residency_permissions.visa.to': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.Visa.To'),
    'verification.residency_permissions.residence_permit.series': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.ResidencePermit.Series'),
    'verification.residency_permissions.residence_permit.number': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.ResidencePermit.Number'),
    'verification.residency_permissions.residence_permit.date_issue': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.ResidencePermit.DateIssue'),
    'verification.residency_permissions.residence_permit.issued_by': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.ResidencePermit.IssuedBy'),
    'verification.residency_permissions.residence_permit.to': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.ResidencePermit.To'),
    'verification.residency_permissions.migration_card.series': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.MigrationCard.Series'),
    'verification.residency_permissions.migration_card.number': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.MigrationCard.Number'),
    'verification.residency_permissions.migration_card.from': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.MigrationCard.From'),
    'verification.residency_permissions.migration_card.to': () =>
      this.translate.get('Verification.VerificationSteps.Field.ResidencyPermissions.MigrationCard.To'),
    'verification.public_official.is_pdl': () =>
      this.translate.get('Verification.VerificationSteps.Field.PublicOfficial.IsPdl'),
    'verification.public_official.bank_dst_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.PublicOfficial.BankDstName'),
    'verification.public_official.bank_src_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.PublicOfficial.BankSrcName'),
    'verification.public_official.bank_bik': () =>
      this.translate.get('Verification.VerificationSteps.Field.PublicOfficial.BankBik'),
    'verification.public_official.bank_corr_account': () =>
      this.translate.get('Verification.VerificationSteps.Field.PublicOfficial.BankCorrAccount'),
    'verification.public_official.bank_giro_account': () =>
      this.translate.get('Verification.VerificationSteps.Field.PublicOfficial.BankGiroAccount'),
    'verification.tariff_plan.account_type': () =>
      this.translate.get('Verification.VerificationSteps.Field.TariffPlan.AccountType'),
    'verification.tariff_plan.has_other_iis': () =>
      this.translate.get('Verification.VerificationSteps.Field.TariffPlan.HasOtherIis'),
    'verification.tariff_plan.other_iis_holder_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.TariffPlan.OtherIisHolderName'),
    'verification.tariff_plan.other_iis_agreement': () =>
      this.translate.get('Verification.VerificationSteps.Field.TariffPlan.OtherIisAgreement'),
    'verification.tariff_plan.other_iis_statement': () =>
      this.translate.get('Verification.VerificationSteps.Field.TariffPlan.OtherIisStatement'),
    'verification.tariff_plan.other_iis_closure_confirmation': () =>
      this.translate.get('Verification.VerificationSteps.Field.TariffPlan.OtherIisClosureConfirmation'),
    'verification.tariff_plan.other_iis_brokerage_report': () =>
      this.translate.get('Verification.VerificationSteps.Field.TariffPlan.OtherIisBrokerageReport'),
    'verification.tariff_plan.allow_broker_use_funds': () =>
      this.translate.get('Verification.VerificationSteps.Field.TariffPlan.AllowBrokerUseFunds'),
    'verification.tariff_plan.standard_brokerage_account': () =>
      this.translate.get('Verification.VerificationSteps.Field.TariffPlan.StandardBrokerageAccount'),
    'verification.additional_data.rub_bank_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.RubBankName'),
    'verification.additional_data.rub_bank_account': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.RubBankAccount'),
    'verification.additional_data.rub_bank_correspondent_account': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.RubBankCorrespondentAccount'),
    'verification.additional_data.rub_bank_bik': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.RubBankBik'),
    'verification.additional_data.rub_additional_details': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.RubAdditionalDetails'),
    'verification.additional_data.cur_bank_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CurBankName'),
    'verification.additional_data.cur_bank_account': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CurBankAccount'),
    'verification.additional_data.cur_account_currency': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CurAccountCurrency'),
    'verification.additional_data.cur_bank_correspondent_account': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CurBankCorrespondentAccount'),
    'verification.additional_data.cur_bank_swift': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CurBankSwift'),
    'verification.additional_data.cur_intermediary_bank_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CurIntermediaryBankName'),
    'verification.additional_data.cur_intermediary_bank_swift': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CurIntermediaryBankSwift'),
    'verification.additional_data.cur_additional_details': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CurAdditionalDetails'),
    'verification.additional_data.work_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.WorkName'),
    'verification.additional_data.work_position': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.WorkPosition'),
    'verification.additional_data.work_address': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.WorkAddress'),
    'verification.additional_data.work_phone': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.WorkPhone'),
    'verification.additional_data.is_self_employed': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.IsSelfEmployed'),
    'verification.additional_data.self_employed_planned_works': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.SelfEmployedPlannedWorks'),
    'verification.additional_data.self_employed_source_of_income': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.SelfEmployedSourceOfIncome'),
    'verification.additional_data.self_employed_tax_regime': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.SelfEmployedTaxRegime'),
    'verification.additional_data.is_self_employed_financial_economic_activity': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.IsSelfEmployedFinancialEconomicActivity'),
    'verification.additional_data.financial_economic_activity': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.FinancialEconomicActivity'),
    'verification.additional_data.has_opened_bank_account': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.HasOpenedBankAccount'),
    'verification.additional_data.name_tin_of_credit_institution': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.NameTinOfCreditInstitution'),
    'verification.additional_data.bankruptcy_petition': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.BankruptcyPetition'),
    'verification.additional_data.restructuring_procedure': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.RestructuringProcedure'),
    'verification.additional_data.completed_debt_restructuring': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CompletedDebtRestructuring'),
    'verification.additional_data.court_bankruptcy': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.CourtBankruptcy'),
    'verification.additional_data.overdue_financial_obligations': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.OverdueFinancialObligations'),
    'verification.additional_data.unfulfilled_obligations': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.UnfulfilledObligations'),
    'verification.additional_data.authorized_capital_info': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.AuthorizedCapitalInfo'),
    'verification.additional_data.manager_name': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerName'),
    'verification.additional_data.manager_position': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerPosition'),
    'verification.additional_data.manager_citizenship': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerCitizenship'),
    'verification.additional_data.manager_birth_place': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerBirthPlace'),
    'verification.additional_data.manage_index': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManageIndex'),
    'verification.additional_data.manager_address': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerAddress'),
    'verification.additional_data.manager_certifying_document': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerCertifyingDocument'),
    'verification.additional_data.manager_document_series': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerDocumentSeries'),
    'verification.additional_data.manager_document_number': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerDocumentNumber'),
    'verification.additional_data.manager_document_date_issue': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerDocumentDateIssue'),
    'verification.additional_data.manager_document_department_code': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerDocumentDepartmentCode'),
    'verification.additional_data.manager_document_issued_by': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerDocumentIssuedBy'),
    'verification.additional_data.manager_date_birth': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerDateBirth'),
    'verification.additional_data.manager_inn': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerInn'),
    'verification.additional_data.manager_snils': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.ManagerSnils'),
    'verification.additional_data.is_fatca': () =>
      this.translate.get('Verification.VerificationSteps.Field.AdditionalData.IsFatca'),
    'verification.account_type.bo': () =>
      this.translate.get('Verification.VerificationSteps.Wizards.IndividualTariffPlan.AccountType.Bo'),
    'verification.account_type.bo_hint': () =>
      this.translate.get('Verification.VerificationSteps.Wizards.IndividualTariffPlan.AccountType.BoHint'),
    'verification.account_type.iisbo': () =>
      this.translate.get('Verification.VerificationSteps.Wizards.IndividualTariffPlan.AccountType.IisBo'),
    'verification.account_type.iisbo_hint': () =>
      this.translate.get('Verification.VerificationSteps.Wizards.IndividualTariffPlan.AccountType.IisBoHint'),
    'enums.tin_or_ssn_missing_reason.not_assigned_now': () =>
      this.translate.get('Verification.VerificationSteps.SelectOptions.TinOrSsnMissingReason.NotAssignedNow'),
    'enums.tin_or_ssn_missing_reason.not_assigned_in_past': () =>
      this.translate.get('Verification.VerificationSteps.SelectOptions.TinOrSsnMissingReason.NotAssignedInPast'),
    'enums.tin_or_ssn_missing_reason.other': () =>
      this.translate.get('Verification.VerificationSteps.SelectOptions.TinOrSsnMissingReason.Other'),
    id: () => this.translate.get('Payment.BankRequisites.Id'),
    currency: () => this.translate.get('Payment.BankRequisites.Currency'),
    beneficiary_bank_name: () => this.translate.get('Payment.BankRequisites.BeneficiaryBankName'),
    recipient: () => this.translate.get('Payment.BankRequisites.Recipient'),
    bik: () => this.translate.get('Payment.BankRequisites.Bik'),
    correspondent_account: () => this.translate.get('Payment.BankRequisites.CorrespondentAccount'),
    settlement_account: () => this.translate.get('Payment.BankRequisites.SettlementAccount'),
    inn: () => this.translate.get('Payment.BankRequisites.Inn'),
    kpp: () => this.translate.get('Payment.BankRequisites.Kpp'),
    beneficiary_bank_swift: () => this.translate.get('Payment.BankRequisites.BeneficiaryBankSwift'),
    payment_purpose: () => this.translate.get('Payment.BankRequisites.PaymentPurpose'),
    'open_new_account.allow_broker_use_funds': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.AllowBrokerUseFunds'),
    'open_new_account.account_type': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.AccountType'),
    'open_new_account.has_other_iis': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.HasOtherIis'),
    'open_new_account.other_iis_holder_name': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.OtherIisHolderName'),
    'open_new_account.other_iis_agreement': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.OtherIisAgreement'),
    'open_new_account.other_iis_brokerage_report': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.OtherIisBrokerageReport'),
    'open_new_account.other_iis_closure_confirmation': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.OtherIisClosureConfirmation'),
    'open_new_account.other_iis_statement': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.OtherIisStatement'),
    'open_new_account.account_type.bo': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.AccountType.Bo'),
    'open_new_account.account_type.bo_hint': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.AccountType.BoHint'),
    'open_new_account.account_type.iisbo': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.AccountType.IisBo'),
    'open_new_account.account_type.iisbo_hint': () =>
      this.translate.get('Briefcase.CreateNewAccount.Wizard.OpenNewAccount.AccountType.IisBoHint'),
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  constructor(private readonly translate: TranslateService) {}

  public registerLabels(labels: TLabels): void {
    Object.assign(this.labelsDictionary, labels);
  }

  public getLabel(key: string): Observable<string> {
    const labelObservable = this.labelsDictionary[key];
    return labelObservable ? labelObservable() : this.translate.get(key);
  }
}

type TLabels = Record<string, () => Observable<string>>;
