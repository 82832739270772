import {IDeviceFingerprint} from '@app/core/device-fingerprint/interfaces/device-fingerprint';

export default class DeviceFingerprintBuilder {
  private readonly fingerprint: IDeviceFingerprint = {};

  public addWebGlInfo(): this {
    try {
      const gl = document.createElement('canvas').getContext('webgl');
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');

      this.fingerprint.webgl_vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
      this.fingerprint.webgl_renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      return this;
    } catch {
      return this;
    }
  }

  public addTimezoneInfo(): this {
    this.fingerprint.timezoneOffset = new Date().getTimezoneOffset();
    this.fingerprint.web_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this;
  }

  public addNavigatorInfo(): this {
    this.fingerprint.system_lang = navigator.language;
    this.fingerprint.user_agent = navigator.userAgent;
    // eslint-disable-next-line deprecation/deprecation
    this.fingerprint.platform = navigator.platform;

    return this;
  }

  public addCanvasFingerprint(): this {
    const canvas = document.createElement('canvas');
    canvas.width = 240;
    canvas.height = 140;
    canvas.style.display = 'inline';
    const context = canvas.getContext('2d');

    // if canvas is not supported
    if (!context || !canvas.toDataURL) {
      this.fingerprint.canvas_code = '';

      return this;
    }

    context.rect(0, 0, 10, 10);
    context.rect(2, 2, 6, 6);
    context.textBaseline = 'alphabetic';
    context.fillStyle = '#f60';
    context.fillRect(125, 1, 62, 20);
    context.fillStyle = '#069';
    context.font = '11pt no-real-font-123';

    const printedText = 'Cwm fjordbank \ud83d\ude03 gly';
    context.fillText(printedText, 2, 15);
    context.fillStyle = 'rgba(102, 204, 0, 0.2)';
    context.font = '18pt Arial';
    context.fillText(printedText, 4, 45);

    context.globalCompositeOperation = 'multiply';
    context.fillStyle = 'rgb(255,0,255)';
    context.beginPath();
    context.arc(50, 50, 50, 0, Math.PI * 2, true);
    context.closePath();
    context.fill();
    context.fillStyle = 'rgb(0,255,255)';
    context.beginPath();
    context.arc(100, 50, 50, 0, Math.PI * 2, true);
    context.closePath();
    context.fill();
    context.fillStyle = 'rgb(255,255,0)';
    context.beginPath();
    context.arc(75, 100, 50, 0, Math.PI * 2, true);
    context.closePath();
    context.fill();
    context.fillStyle = 'rgb(255,0,255)';
    context.arc(75, 75, 75, 0, Math.PI * 2, true);
    context.arc(75, 75, 25, 0, Math.PI * 2, true);
    context.fill('evenodd');

    this.fingerprint.canvas_code = canvas.toDataURL();

    return this;
  }

  public build(): IDeviceFingerprint {
    return this.fingerprint;
  }
}
