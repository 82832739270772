import {DOCUMENT} from '@angular/common';
import {inject, Injectable} from '@angular/core';
import {ALocaleStorage} from '@app/shared/storages/local-storage';
import {TranslateService} from '@ngx-translate/core';

import {WINDOW} from '../tokens/window.token';
import {createUrlForNewVersion} from '../utils/create-url-for-new-version.function';

export const enum EDesignVersion {
  New = 'new',
  Old = 'old',
}

@Injectable({
  providedIn: 'root',
})
export class NewInterfaceInteractionService {
  private static readonly DESIGN_VERSION_COOKIE_KEY = 'isNewB2core';

  private readonly window = inject(WINDOW);
  private readonly document = inject(DOCUMENT);
  private readonly translateService = inject(TranslateService);

  public goToNewInterface(): void {
    ALocaleStorage.DESIGN_VERSION.set(EDesignVersion.New);
    this.setCookie(NewInterfaceInteractionService.DESIGN_VERSION_COOKIE_KEY);
    this.reloadPage();
  }

  public syncCurrentState(): void {
    const currentVersion = ALocaleStorage.DESIGN_VERSION.get();

    if (!currentVersion) {
      ALocaleStorage.DESIGN_VERSION.set(EDesignVersion.Old);

      return;
    }

    if (currentVersion === EDesignVersion.New) {
      this.goToNewInterface();
    }
  }

  private reloadPage(): void {
    this.window.history.pushState({}, '', createUrlForNewVersion(this.translateService.currentLang, this.window));
    this.window.location.reload();
  }

  private setCookie(name: string): void {
    this.document.cookie = `${name}=true; path=/`;
  }
}
