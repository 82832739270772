export const PAGES_WITH_URLS = new Map([
  ['login', 'auth'],
  ['dashboard', 'dashboard'],
  ['wallet', 'balances'],
  ['tx/payment', 'funds/deposit'],
  ['tx/payout', 'funds/withdrawal'],
  ['tx/internal-transfer', 'funds/withdrawal?isInternalTransfer=true'],
  ['exchange', 'funds/exchange'],
  ['tx/transfer', 'funds/transfer'],
  ['history/transactions', 'transactions'],
  ['profile/settings', 'profile-info'],
  ['profile/verification', 'verification'],
  ['profile/security', 'security'],
  ['profile/finance', 'withdraw-patterns'],
  ['helpdesk', 'helpdesk'],
  ['trade/platform/marginal-accounts', 'platforms/one-zero'],
  ['trade/platform/MetaTrader4', 'platforms/mt4'],
  ['trade/platform/MetaTrader5', 'platforms/mt5'],
  ['trade/platform/cTrader', 'platforms/cTrader'],
  ['ib-room/dashboard', 'ib-room/dashboard'],
  ['ib-room/promo/banners/:partnerId', 'ib-room/promo/:partnerId/banners'],
  ['ib-room/promo/links/:partnerId', 'ib-room/promo/:partnerId/links'],
  ['ib-room/reports/acquisitions/:partnerId', 'ib-room/reports/:partnerId/acquisitions'],
  ['ib-room/reports/client-list/:partnerId', 'ib-room/reports/:partnerId/client-list'],
  [
    'ib-room/reports/client-list/:partnerId/client-details/:clientId/personal-data',
    'ib-room/reports/:partnerId/client-list/:clientId/rewards',
  ],
  [
    'ib-room/reports/client-list/:partnerId/client-details/:clientId/rewards',
    'ib-room/reports/:partnerId/client-list/:clientId/rewards',
  ],
  [
    'ib-room/reports/client-list/:partnerId/client-details/:clientId/accounts',
    'ib-room/reports/:partnerId/client-list/:clientId/accounts',
  ],
  [
    'ib-room/reports/client-list/:partnerId/client-details/:clientId/sub-clients',
    'ib-room/reports/:partnerId/client-list/:clientId/sub-clients',
  ],
  ['ib-room/reports/accounts/:partnerId', 'ib-room/reports/:partnerId/accounts'],
  ['ib-room/reports/rewards/:partnerId', 'ib-room/reports/:partnerId/rewards'],
  ['ib-room/reports/transactions/:partnerId', 'ib-room/reports/:partnerId/transactions'],
  ['ib-room/reports/deposits/:partnerId', 'ib-room/reports/:partnerId/deposits'],
  ['ib-room/reports/withdrawals/:partnerId', 'ib-room/reports/:partnerId/withdrawals'],
  ['ib-room/reports/trades/:partnerId', 'ib-room/reports/:partnerId/trades'],
]);
