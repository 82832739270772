import {Casts, CastTypes, Model} from '@app/core/model';
import {EPlatformClass} from '@app/core/models/account/enum/platform-class';
import {EPlatformName} from '@app/core/models/account/enum/platform-name';

export class Platform extends Model {
  public id: number;
  public name: string;
  public class: EPlatformClass;
  public caption: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public short_caption: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public enabled: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public has_multiple_type_password?: boolean;

  public readonly casts: Casts = {
    id: [CastTypes.Number],
    name: [CastTypes.String],
    class: [CastTypes.String],
    caption: [CastTypes.String],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    short_caption: [CastTypes.String],
    enabled: [CastTypes.Boolean],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    has_multiple_type_password: [CastTypes.Undefined, CastTypes.Boolean],
  };

  public get tradePlatformNameWithAnalytics():
    | EPlatformName.Mt4
    | EPlatformName.Mt5
    | EPlatformName.CTrader
    | undefined {
    switch (this.class) {
      case EPlatformClass.MetaTrader4:
        return EPlatformName.Mt4;
      case EPlatformClass.MetaTrader5:
        return EPlatformName.Mt5;
      case EPlatformClass.CTrader:
        return EPlatformName.CTrader;
      default:
        return undefined;
    }
  }

  public isB2Margin(): boolean {
    return this.class === EPlatformClass.B2Margin;
  }

  public isOneZero(): boolean {
    return this.class === EPlatformClass.OneZero;
  }

  public isPrimeXm(): boolean {
    return this.class === EPlatformClass.PrimeXm;
  }

  public isWrapper(): boolean {
    return this.class === EPlatformClass.Wrapper;
  }

  public isPersonal(): boolean {
    return this.class === EPlatformClass.Personal;
  }

  public isB2Trader(): boolean {
    return this.class === EPlatformClass.B2Trader;
  }
}
