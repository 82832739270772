import {Identify, identify as setUserProperties} from '@amplitude/analytics-browser';
import {EnrichmentPlugin} from '@amplitude/analytics-types';

import {IDefaultEventProperties} from '../interfaces/default-event-properties.interface';

export class UserPropertiesEnrichmentPlugin implements EnrichmentPlugin {
  private readonly defaultEventProperties: IDefaultEventProperties;

  constructor(config: IDefaultEventProperties) {
    this.defaultEventProperties = config;
  }

  public async setup(): Promise<void> {
    const identify = new Identify();

    identify.set('vendor', this.defaultEventProperties.vendor);
    identify.set('app_environment_type', this.defaultEventProperties.appEnvironmentType);
    identify.set('app_version', this.defaultEventProperties.appVersion);
    identify.set('app_source', this.defaultEventProperties.appSource);

    await setUserProperties(identify).promise;
  }
}
