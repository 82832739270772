export enum EEventType {
  AntiphishingCode = 'Antiphishing code',
  AddWidgetToDashboard = 'Add widget to dashboard',
  SuccessfulVerificationRequest = 'Successful verification request',
  AddWallet = 'Add wallet',
  HelpdeskTicketAction = 'Helpdesk ticket action',
  Transaction = 'Transaction',
  WhitelistAddressAdded = 'Add whitelist address',
  AccountCreated = 'Account created',
  BonusClaimed = 'Bonus claimed',
  ApplicationForAdmissionToIb = 'Application for admission to IB',
}
