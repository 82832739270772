import {defaultMethods} from './helpers/default-methods';

export abstract class ALocaleStorage {
  public static readonly LEVERAGE_HISTORY = defaultMethods('LEVERAGE_HISTORY');
  public static readonly LAST_OPENED_BANNERS = defaultMethods('lastOpenedBanners');
  public static readonly LANG = defaultMethods('lang');
  public static readonly COMMON_THEME = defaultMethods('commonTheme');
  public static readonly ADVANCED_THEME = defaultMethods('advancedTheme');
  public static readonly COOKIE_MESSAGE = defaultMethods('cookie-message');
  public static readonly ACCESS_TOKEN = defaultMethods('access_token');
  public static readonly REFRESH_TOKEN = defaultMethods('refresh_token');
  public static readonly NEW_VERSION_ACCESS_TOKEN_EXPIRED_AT = defaultMethods('accessTokenExpiredAt');
  public static readonly NEW_VERSION_ACCESS_TOKEN = defaultMethods('accessToken');
  public static readonly NEW_VERSION_REFRESH_TOKEN_EXPIRED_AT = defaultMethods('refreshTokenExpiredAt');
  public static readonly NEW_VERSION_REFRESH_TOKEN = defaultMethods('refreshToken');
  public static readonly REFERRAL_ID = defaultMethods('referral_id');
  public static readonly REFERRAL_CXD_TOKEN = defaultMethods('referral_cxd_token');
  public static readonly DIALOG_MOEX_SELECT_INSTRUMENT_COMPONENT_KEY = defaultMethods(
    'DialogMoexSelectInstrumentComponentSettingKey',
  );
  public static readonly PBSR_HIDE_UPGRADE_LEVEL_NOTIFICATION = defaultMethods('pbsrHideUpgradeLevelNotification');
  public static readonly PBSR_HIDE_SIGN_DOCUMENTS_NOTIFICATION = defaultMethods('pbsrHideVerificationNotification');
  public static readonly IS_PROTECT_BALANCES = defaultMethods('is_protect_balances');
  public static readonly PBS_FOOTER_STATE = defaultMethods('PbsFooterState');
  public static readonly B2TRADER_API_DATA = defaultMethods('b2trader_api_data');
  public static readonly PBSR_VERIFICATION_SUCCEED = defaultMethods('pbsrVerificationSucceed');
  public static readonly PBSR_IS_INDIVIDUAL = defaultMethods('pbsrIsIndividual');
  public static readonly PBSR_VERIFICATION_STEP_DATA = defaultMethods('pbsrVerificationStepData');
  public static readonly TRADING_BOARD_IS_24H_TIME_FORMAT = defaultMethods('tradingBoardIs24hTimeFormat');

  public static readonly EQWIRE_ONBOARDING_REQUEST_STATUS = defaultMethods('eqwire_request_status');
  public static readonly EQWIRE_ONBOARDING_BUSINESS_FORM_STEPS = defaultMethods('eqwire_business_form_steps');
  public static readonly EQWIRE_ONBOARDING_INDIVIDUAL_FORM_STEPS = defaultMethods('eqwire_individual_form_steps');
  public static readonly EQWIRE_ONBOARDING_ERRORS = defaultMethods('eqwire_onboarding_errors');
  public static readonly EQWIRE_ONBOARDING_REQUEST_ALREADY_EXIST = defaultMethods(
    'eqwire_onboarding_form_request_already_exist',
  );
  public static readonly EQWIRE_ONBOARDING_REQUEST_ID = defaultMethods('eqwire_onboarding_form_request_id');
  public static readonly EQWIRE_ONBOARDING_FLOW_TYPE = defaultMethods('eqwire_onboarding_flow_type');
  public static readonly EQWIRE_TELLMONEY_CONSENT_ID = defaultMethods('eqwire_tellmoney-consent-id');
  public static readonly DESIGN_VERSION = defaultMethods('DESIGN_VERSION');

  public static getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  public static setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public static clear(): void {
    localStorage.clear();
  }
}
